import React, { useCallback, useEffect, useState } from "react";

import { faDownload, faRocket, faSave } from "@fortawesome/free-solid-svg-icons";
import { ImageListType, ImageType } from "react-images-uploading";
import { useParams } from "react-router-dom";

import Button from "src/components/button";
import { FunctionalIconBar } from "src/components/contentOverview/functionalIconBar";
import { TypeIcon } from "src/components/contentOverview/typeIcon";
import { IContentInformation, dateToString } from "src/components/contentOverview/uploadedContent";
import { UploadImages } from "src/components/newUpload/uploadImages";
import { UploadJSON } from "src/components/newUpload/uploadJSON";
import { ContentStatus } from "src/configuration/types";
import { getContentByID } from "src/functions/apiCalls";
import { buildImageURL } from "src/functions/buildURL";
import { publish } from "src/functions/changeContentStatus";
import { downloadFile } from "src/functions/download";
import { Notify } from "src/functions/notification";
import { IArticle, IReferencedImage, extractImages } from "src/functions/validator/validateJSON";
import "src/styling/scss/contentDetail.scss";

import { saveChanges } from "./saveChanges";

export const ContentDetail: React.FC = () => {
  const [uploadedImages, setUploadedImages] = useState<ImageListType>([]);
  const [uploadedContent, setUploadedContent] = useState<IArticle | null>(null);
  const [referencedImages, setReferencedImages] = useState<IReferencedImage[]>([]);
  const [contentInformation, setContentInformation] = useState<IContentInformation | null | undefined>(undefined);
  const [initialImages, setInitialImages] = useState<IReferencedImage[]>([]);

  const { id } = useParams();

  const reload = useCallback(() => {
    if (!id) {
      return;
    }

    getContentByID(id)
      .then((resultData) => {
        if (resultData) {
          setContentInformation(resultData);
          if (resultData.content && resultData.contentName !== "") {
            const content = JSON.parse(resultData.content);
            setUploadedContent(content);
            const images = extractImages(content);
            setReferencedImages(images);
            setInitialImages(images);

            const imageTypeArray: ImageListType = images.map((image: IReferencedImage) => {
              const url: string = buildImageURL(resultData.id, image.filename);
              const file = new File([], image.filename);
              const imageType: ImageType = { dataURL: url, key: image, file: file };
              return imageType;
            });
            setUploadedImages(imageTypeArray);
          } else {
            setContentInformation(null);
          }
        } else {
          setContentInformation(null);
        }
      })
      .catch(() => {
        setContentInformation(null);
        Notify.error("Reload fehlgeschlagen");
      });
  }, [id]);

  useEffect(() => {
    reload();
  }, [reload]);

  if (contentInformation === null || contentInformation === undefined) {
    return (
      <div className="container">
        <div>
          <h2 className="currentText">Detailseite</h2>
          <p className="currentText">Hier findest du alle Dateien des ausgewählten Contents auf einen Blick.</p>
        </div>
        {contentInformation === null ? (
          <p className="currentText error">Der ausgewählte Content existiert nicht in der Datenbank!</p>
        ) : (
          <p className="currentText">Loading</p>
        )}
      </div>
    );
  }

  return (
    <div className="contentDetail">
      <div>
        <h2 className="currentText">Detailseite</h2>
        <p className="currentText">Hier findest du alle Dateien des ausgewählten Contents auf einen Blick.</p>
      </div>
      <table className="row">
        <tbody>
          <tr>
            <td className="noBorder status">
              <TypeIcon currentContentStatus={contentInformation.contentStatus} text={true} />
            </td>
            <td className="noBorder right">
              <label className="contentType light">{contentInformation.contentType}</label>
            </td>
            <td className="noBorder right">
              <label className="imageName light">{dateToString(contentInformation.lastModified)}</label>
            </td>
            <td className="noBorder right">
              <FunctionalIconBar
                currentContentStatus={contentInformation.contentStatus}
                id={contentInformation.id}
                contentName={contentInformation.contentName}
                contentType={contentInformation.contentType}
                reload={reload}
                endOfUrl={
                  contentInformation.publishedEndOfUrl
                    ? contentInformation.publishedEndOfUrl
                    : contentInformation.endOfUrl
                }
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div>
        <UploadJSON
          setUploadedContent={setUploadedContent}
          setReferencedImages={setReferencedImages}
          file={uploadedContent}
        />
        <UploadImages
          setUploadedImages={setUploadedImages}
          images={uploadedImages}
          referencedImages={referencedImages}
        />
      </div>
      <div className="processUpload">
        <Button
          name="Speichern"
          className="submitButton"
          icon={faSave}
          iconPosition="left"
          onClick={async () => {
            if (uploadedContent === null) {
              return;
            }
            const changesSaved = await saveChanges(
              uploadedImages,
              referencedImages,
              initialImages,
              contentInformation.id,
              contentInformation.contentStatus,
              uploadedContent
            );
            if (changesSaved) {
              reload();
            }
          }}
          margin="10px"
        />
        <Button
          name="Veröffentlichen"
          className="submitButton"
          icon={faRocket}
          iconPosition="left"
          onClick={async () => {
            await publish(contentInformation.id);
            reload();
          }}
          disabled={!isPublishPossible(contentInformation.contentStatus)}
          margin="10px"
        />
        <Button
          name="Download JSON"
          className="submitButtonInverse"
          icon={faDownload}
          onClick={() => downloadFile(uploadedContent, "text/json")}
          title="Download JSON"
          iconPosition="left"
          margin="10px"
          disabled={uploadedContent === null}
        />
      </div>
    </div>
  );
};
function isPublishPossible(contentStatus: ContentStatus): boolean {
  return contentStatus === "draft" || contentStatus === "rework" || contentStatus === "archived";
}
